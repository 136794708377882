body {
  user-select: none;
}

hr {
  border: 1px solid;
  margin-top: 0;
}

.navbar-toggler, .navbar-toggler:focus, .navbar-toggler:active, .navbar-toggler-icon:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.toast {
  margin-bottom: 2em;
}

.motd {
  padding-top: 2em;
  font-size: 1.3em;
}

.flex-container {
  display: flex;
}

.flex-left {
  flex-direction: row-reverse;
}

.right {
  flex-direction: row;
}

.fixed-column {
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 12%;
  display: flex;
}

.dynamic-columns {
  flex: 1;
  gap: 1%;
  width: 44%;
  display: flex;
}

.c-1 {
  width: 20vw;
}

.c-2 {
  width: 18vw;
}

.c-3 {
  width: 14vw;
}

.dynamic-columns .column {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.player-name {
  align-items: center;
  padding: .2em;
  font-size: 1.8em;
}

.label {
  align-content: center;
  height: 8vh;
  font-size: 2.5em;
  font-weight: normal;
}

.score-toggle {
  height: 100%;
  position: relative;
}

.add {
  color: #333 !important;
}

.tick:hover {
  cursor: pointer;
}

.tick {
  color: #89b2f5;
  place-content: center;
  font-size: 5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tick-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(90deg);
}

.tick-3 {
  font-size: 4em;
  position: absolute;
  left: 0%;
}

.point {
  color: #89b2f5;
  font-size: 1.5em;
  position: absolute;
  top: 50%;
  left: 2em;
  transform: translate(-50%, -50%);
}

.score {
  color: #89b2f5;
  margin-top: 2vh;
  font-size: 2em;
}

@media only screen and (width <= 768px) {
  .c-1 {
    width: 35vw;
  }

  .c-2 {
    width: 18vw;
  }

  .c-3 {
    width: 16vw;
  }

  .label {
    font-size: 2.5em;
  }

  .point {
    font-size: 1.3em;
    left: 1.7em;
  }

  .tick {
    font-size: 4em;
  }

  .tick-3 {
    font-size: 3.2em;
  }
}

@media only screen and (width <= 576px) {
  .c-1 {
    width: 40vw;
  }

  .c-2 {
    width: 20vw;
  }

  .c-3 {
    width: 18vw;
  }

  .label {
    font-size: 2.5em;
  }

  .point {
    font-size: 1.2em;
    left: 1.7em;
  }

  .score {
    font-size: 1.7em;
  }
}
/*# sourceMappingURL=index.3abb2a77.css.map */
