body {
    user-select: none;
}

hr {
    border: 1px solid;
    margin-top: 0;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.toast {
    margin-bottom: 2em;
}

.motd {
    padding-top: 2em;
    font-size: 1.3em;
}

.flex-container {
    display: flex;
}

.flex-left {
    flex-direction: row-reverse;
}

.right {
    flex-direction: row;
}

.fixed-column {
    width: 12%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.dynamic-columns {
    width: 44%;
    display: flex;
    gap: 1%;
    flex: 1; /* Allow left and right sections to expand equally */
}

.c-1 {
    width: 20vw;
}

.c-2 {
    width: 18vw;
}

.c-3 {
    width: 14vw;
}

.dynamic-columns .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.player-name {
    font-size: 1.8em;
    align-items: center;
    padding: 0.2em;
}

.label {
    font-size: 2.5em;
    font-weight: normal;
    align-content: center;
    height: 8vh;
}

.score-toggle {
    position: relative;
    height: 100%;
}

.add {
    color: #333 !important;
}

.tick:hover {
    cursor: pointer;
}

.tick {
    align-content: center;
    color: #89B2F5;
    font-size: 5em;
    justify-content: center;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}

.tick-2 {
    transform: translate(-50%, -50%) rotate(90deg);
    position: absolute;
    top:50%;
    left:50%;
}

.tick-3 {
    font-size: 4em;
    position: absolute;
    left:0%;
}

.point {
    position: absolute;
    left: 2em;
    top: 50%;
    color: #89B2F5;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
}

.score {
    margin-top: 2vh;
    color: #89B2F5;
    font-size: 2em;
}



@media only screen and (max-width: 768px) {
    .c-1 {
        width: 35vw;
    }

    .c-2 {
        width: 18vw;
    }

    .c-3 {
        width: 16vw;
    }
    .label {
        font-size: 2.5em;
    }
    .point {
        left: 1.7em;
        font-size: 1.3em;
    }
    .tick {
        font-size: 4em;
    }
    .tick-3 {
        font-size: 3.2em;
    }
}


@media only screen and (max-width: 576px) {
    .c-1 {
        width: 40vw;
    }

    .c-2 {
        width: 20vw;
    }

    .c-3 {
        width: 18vw;
    }
    .label {
        font-size: 2.5em;
    }
    .point {
        left: 1.7em;
        font-size: 1.2em;
    }
    .score {
        font-size: 1.7em;
    }

}
